import { defineStore } from 'pinia'
import { UserProfile } from '~/types'

interface AuthState {
  loggedIn: boolean,
  user: UserProfile,
  errors: Object
}

const runtimeConfig = useRuntimeConfig()

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
      loggedIn: false,
      user: {},
      errors: {}
    }),

    actions: {
      async signIn(params: any) {
        const localePath = useLocalePath()

        const { status, error } = await dataFetch('/login', {
          method: 'post', 
          body: params
        },
        useRuntimeConfig().public.publicUrl)
        
        if(status.value == 'success'){
          // Change login state
          this.loggedIn = true;
          // Reset errors state
          this.errors = {}
          
          // Retrieve user
          const { data: user }: any = await dataFetch('/user')

          if(user?.value) {
            // Store user in state
            this.user = user.value.data
            // Store user in cookies
            setCookie('user', JSON.stringify(this.user))

            try {
              // Register device (FCM token)
              await registerDevice()
            } catch (error) {
              console.info('Failed to register device for push notifications:', error)
            }

            const router = useRouter()
            router.push(localePath('/dashboard'))
          } else {
            // Redirect to login page if API response dosn't contain user 
            const router = useRouter();
            router.push(localePath("/login"));
          }
        }else{
          console.log('error!')
          this.errors = error._object[error._key].data
        }
      },

      async signOut(){
        const localePath = useLocalePath()
        let deviceToken = null;

        try {
          const deviceToken = await fetchToken();
        } catch (error) {
          console.info('Failed to fetch device token:', error)
        }

        await dataFetch('/logout', {
          method: 'post',
          body: deviceToken ? { device_token: deviceToken } : {}
        }, useRuntimeConfig().public.publicUrl)
        .then(async () => {
          this.user = {};
          this.loggedIn = false;
          // Delete user cookie
          deleteCookie('user');

          // Retrieve notification store
          const notificationStore: any = useNotificationStore();
          // Re-fetch notifications
          notificationStore.retrieveNotification();

          // Redirect to login page
          const router = useRouter();
          router.push(localePath("/login"));
        })
      },
      
      async authenticated() {
        // If user exists in cookies retrieve the user and prevent API request
        if(getCookie('user')) {
          this.user = JSON.parse(getCookie('user'));
        }

        checkUserLanguage(this.user?.language ?? 'en')

        this.loggedIn = Object.keys(this.user).length > 0 ? true : false;

        if(this.loggedIn) {
          try {
            // Register device (FCM token)
            await registerDevice()
          } catch (error) {
            console.info('Failed to register device for push notifications:', error)
          }
        }

        return this.loggedIn;
      },

      async resetPassword(email: string){
        const { data : success, error } = await dataFetch('/forgot-password', {
          method: 'post', 
          body: {
            email: email
          }
        }, useRuntimeConfig().public.publicUrl)

        return { success, error }
      },

      async newPassword(params: any) {
        const { data : success, error } = await dataFetch(runtimeConfig.public.apiUrl + '/reset-password', {
          method: 'post', 
          body: params
        }, useRuntimeConfig().public.publicUrl)

        return { success, error }
      }
    },
})