import {
  getToken as _getToken,
  onMessage as _onMessage,
  getToken,
} from "firebase/messaging";


const registerDevice = async () => {
  const {$fcm} = useNuxtApp();

  await getToken($fcm.messaging).then(async (token) => {
    let response = await dataFetch("/device-token", {
      method: "post",
      body: {
        device_token: token,
        device_name: window.navigator.userAgent,
        device_type: "web",
      },
    });
  
    if (response.status._value == "error") {
      response.error = response.error._object[response.error._key].data;
    }
  
    return response;
  });  
};

const fetchToken = async () => {
  const {$fcm} = useNuxtApp();

  const token = await getToken($fcm.messaging).then((token) => {
    return token;
  });

  return token;
};

export { registerDevice, fetchToken };
